import {
    TERMS_OF_USE_URL,
    PRIVACY_POLICY_URL,
    TERMS_OF_USE_URL_GEORGIAN,
    PRIVACY_POLICY_URL_GEORGIAN
} from '@/consts';

export const getTermsOfUseURLByLang = (lang = 'en') => {
    if (lang === 'ka') {
        return TERMS_OF_USE_URL_GEORGIAN;
    }

    return `${TERMS_OF_USE_URL}/${lang}`;
} 

export const getPrivacyPolicyURLeByLang = (lang = 'en') => {
    if (lang === 'ka') {
        return PRIVACY_POLICY_URL_GEORGIAN;
    }

    return `${PRIVACY_POLICY_URL}/${lang}`
} 